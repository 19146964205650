import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { FC, ReactNode } from 'react';

import { colors } from '../../theme/colors';
import { PaildButton } from '../atoms/PaildButton';

type Props = {
  isSeminar?: boolean;
  children: ReactNode;
};

export const CompleteLayout: FC<Props> = ({ children, isSeminar }) => {
  return (
    <div css={CompleteLayoutStyle}>
      {isSeminar ? (
        <StaticImage
          src='../../images/complete/seminar-complete.svg'
          alt='registration-complete'
          quality={100}
          placeholder='none'
          objectFit='contain'
          css={{
            marginBottom: 20,
            '@media(max-width: 768px)': {
              maxWidth: 256,
            },
          }}
        />
      ) : (
        <StaticImage
          src='../../images/complete/registration-complete.svg'
          alt='registration-complete'
          quality={100}
          placeholder='none'
          objectFit='contain'
          css={{
            marginBottom: 20,
            '@media(max-width: 768px)': {
              maxWidth: 256,
            },
          }}
        />
      )}
      {children}
      <Link to='/' css={{ marginTop: 40, width: '100%', maxWidth: 335 }}>
        <PaildButton background={colors.primary} arrow>
          トップページへ戻る
        </PaildButton>
      </Link>
    </div>
  );
};

const CompleteLayoutStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 40,
  marginBottom: 64,
  padding: '0 17px',

  h2: {
    fontSize: 40,
    br: { display: 'none' },
  },

  p: {
    fontSize: 16,
    textAlign: 'center',
  },

  '@media(max-width: 1119px)': {
    fontSize: 28,
    marginBottom: 32,

    h2: {
      fontSize: 28,
    },

    p: {
      fontSize: 14,
    },
  },

  '@media(max-width: 768px)': {
    h2: {
      textAlign: 'center',
      br: { display: 'inline' },
    },

    p: {
      textAlign: 'left',
      br: { display: 'none' },
    },
  },
});
