import { CompleteLayout } from '../../components/forms/CompleteLayout';
import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';

export const Head = () => (
  <HeadInner
    path='/registration-partner-accountant/complete/'
    title='ご登録ありがとうございます'
    noindex
  />
);

const CompletePage = () => {
  const links = [
    {
      name: `paildパートナープログラム`,
      path: `/registration-partner-accountant/`,
    },
    {
      name: `パートナープログラムの登録を受け付けました。`,
      path: `/registration-partner-accountant/complete/`,
    },
  ];

  return (
    <Layout
      header='simple'
      footer='simple'
      background='simple'
      isBottomMenu={false}
      links={links}
    >
      <CompleteLayout>
        <h2>パートナープログラムの登録を受け付けました。</h2>
        <p>
          メールにて顧客紹介提案用資料と
          <br />
          顧客用申込みURLをお送りしますのでご確認ください。
        </p>
      </CompleteLayout>
    </Layout>
  );
};

export default CompletePage;
